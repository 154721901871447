<template>
  <div class="cont">
    <el-row class="search-box">
      <el-row class="search-row">
        <el-row class="search-item">
          <el-date-picker value-format="yyyy-MM-dd" @change="getSumFormData" v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-row>
      </el-row>
    </el-row>
    <div class="title">
      <h3>收款概况</h3>
    </div>
    <el-row class="card-box" type="flex">
      <dl v-for="(item, index) in parseStatisticsTotal" :key="index">
        <dt>
          <div :class="['image', `image${index+1}`]"></div>
        </dt>
        <dd>
          <p>{{ item.name }}</p><span>¥{{ item.total }}</span>
        </dd>
      </dl>
    </el-row>

    <div class="chart-box">
      <el-row class="chart-wrap">
        <el-col :span="12">
          <div class="title m-top-50">
            <h3>总收款</h3>
          </div>
          <div class="chart">
            <div id="chart1"></div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="title m-top-50">
            <h3>总退款</h3>
          </div>
          <div class="chart">
            <div id="chart2"></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="title m-top-50">
      <h3>支付方式明细<span>{{ dateRange[0] + '~' +dateRange[1] }}</span></h3>
    </div>
    <div class="chart-box">
      <el-tabs v-model="makeType" @tab-click="handleClick">
        <el-tab-pane label="净收款" name="1"></el-tab-pane>
        <el-tab-pane label="收款" name="2"></el-tab-pane>
        <el-tab-pane label="退款" name="3"></el-tab-pane>
        <el-tab-pane label="收押金" name="4"></el-tab-pane>
        <el-tab-pane label="退押金" name="5"></el-tab-pane>
      </el-tabs>
      <el-row type="flex">
        <el-button style="margin-left:auto;display:block" icon="el-icon-back" @click="changeData('before')" :disabled="page===0">前{{limit}}天</el-button>
        <el-button @click="changeData('after')" :disabled="originData.sliceDates && originData.sliceDates.length < limit">后{{limit}}天<i class="el-icon-right el-icon--right"></i></el-button>
        <el-button size="medium" type="primary" @click="handleExcel">报表导出</el-button>
      </el-row>

      <el-row type="flex" justify="betweent" class="tab-header m-top-20">
        <el-col class="tab-body-item">支付方式</el-col>
        <el-col class="tab-body-item">合计</el-col>
        <!-- <el-col v-for="(item,idx) in spliceData" :key="idx">{{item}}</el-col> -->
        <el-col class="tab-body-item" v-for="(item,idx) in originData.sliceDates" :key="idx">{{item}}</el-col>
      </el-row>
      <el-row type="flex" justify="betweent" class="tab-body" v-for="(list,listIdx) in originData.list" :key="listIdx">
        <el-col class="tab-body-item">{{ list.name}}</el-col>
        <el-col class="tab-body-item">￥{{list.totalAmount}}</el-col>
        <!-- <el-col class="tab-body-item" v-for="(item,key) in spliceData" :key="key">{{item}}</el-col> -->
        <el-col class="tab-body-item" v-for="(money,moneyIdx) in list.sliceDailyAmounts" :key="moneyIdx">￥{{money}}</el-col>
      </el-row>

      <!-- <el-row class="tab-body">客房消费</el-row>
      <el-row class="tab-body">违约金</el-row> -->
      <el-row class="tab-body" type="flex" justify="betweent">
        <el-col class="tab-body-item">合计</el-col>
        <el-col class="tab-body-item">￥{{originData.allTotalAmount}}</el-col>
        <el-col class="tab-body-item" v-for="(item,idx) in originData.sliceDailyAllAmounts" :key="idx">￥{{item}}</el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {
  getTotal,
  getSumFormData,
  processRecordExcel,
  exportSummaryExcel,
} from "@/api/pms/statistics/proceedsSummary";
import { mapState } from "vuex";
import { dateFactory, getDict, exportFile } from "@/common/js/common";
export default {
  data() {
    return {
      crumbs: new Map([["PMS"], ["统计中心"], ["收款汇总"]]),
      dateRange: [
        dateFactory.getDistanceToday(-7, false),
        dateFactory.getDistanceToday(0, false),
      ],
      page: 0,
      limit: 10,
      originData: "",
      dataLength: 0,
      statisticsTotal: {
        totalReceive: 0,
        totalRefund: 0,
        netReceipts: 0,
      },
      makeType: "1",
      detailTotal: {
        totalReceive: 0,
        totalRefund: 0,
        netReceipts: 0,
      },
      projectIds: [],
    };
  },
  computed: {
    ...mapState(["hotelInfo", "dictData"]),
    parseStatisticsTotal() {
      const totalArr = [
        { name: "净收入(元)", code: "netReceipts", total: 0 },
        { name: "总收入(元)", code: "totalReceive", total: 0 },
        { name: "总退款(元)", code: "totalRefund", total: 0 },
      ];
      for (const k in this.statisticsTotal) {
        totalArr.find((i) => i.code === k).total = this.statisticsTotal[k];
      }
      return totalArr;
    },
  },
  mounted() {
    this.limit = sessionStorage.getItem("pageSize");
    getDict("pay-method");
    this.getGeneralStatistics();
    this.getSumFormData();
  },
  methods: {
    handleExcel() {
      let params = {
        hotelId: this.hotelInfo.id,
        beginDateStr: this.dateRange[0],
        endDateStr: this.dateRange[1],
        type: this.makeType,
      };
      // exportSummaryExcel(params).then( res => {
      //   exportFile(res,"营业汇总")
      // })
      // this.$axios.get(url,params,{},"blob").then(res => {
      //   exportFile(res,"营业汇总")
      // })
      processRecordExcel(params).then((res) => {
        exportFile(res, "收款汇总");
      });
    },
    handleClick(e) {
      this.getSumFormData();
    },
    changeData(type) {
      // 前几天后几天
      if (type === "before") {
        if (this.page <= 0) {
          return;
        }
        this.page--;
      }
      if (type === "after") {
        if (this.page * this.limit >= this.dataLength) {
          return;
        }
        this.page++;
      }
      let originData = this.originData;
      originData.sliceDates = originData.dates.slice(
        this.page * this.limit,
        (this.page + 1) * this.limit
      );
      originData.sliceDailyAllAmounts = originData.dailyAllAmounts.slice(
        this.page * this.limit,
        (this.page + 1) * this.limit
      );
      originData.list.map((list) => {
        list.sliceDailyAmounts = list.dailyAmounts.slice(
          this.page * this.limit,
          (this.page + 1) * this.limit
        );
      });
    },
    getSumFormData() {
      // 报表数据
      let data = {
        hotelId: this.hotelInfo.id,
        beginDateStr: this.dateRange[0],
        endDateStr: this.dateRange[1],
        type: this.makeType,
      };
      getSumFormData(data).then((res) => {
        this.dataLength = res.records.dates.length;
        res.records.sliceDates = res.records.dates.slice(0, this.limit);
        res.records.sliceDailyAllAmounts = res.records.dailyAllAmounts.slice(
          0,
          this.limit
        );
        res.records.list.forEach((list) => {
          list.sliceDailyAmounts = list.dailyAmounts.slice(0, this.limit);
        });
        this.originData = res.records;
        this.getGeneralStatistics();
      });
    },
    // 获取收款概况统计
    getGeneralStatistics() {
      const params = {
        hotelId: this.hotelInfo.id,
        companyId: this.hotelInfo.storeId,
        beginDateStr: this.dateRange[0],
        endDateStr: this.dateRange[1],
      };
      getTotal(params).then(({ success, records }) => {
        if (success) {
          this.statisticsTotal.netReceipts = records.netReceipts;
          this.statisticsTotal.totalRefund = records.totalRefund;
          this.statisticsTotal.totalReceive = records.totalReceive;
          const { receiveList, refundList } = records;
          const totalArr = [receiveList, refundList];
          const totalAmount = [records.totalReceive, records.totalRefund];
          const option = {
            tooltip: {
              trigger: "item",
            },
            legend: {
              top: "5%",
              left: "10%",
            },
            color: ["#0881fe", "#8ce98a", "#f97f84", "#ffbf7e"],
            series: [
              {
                type: "pie",
                radius: ["40%", "70%"],
                avoidLabelOverlap: false,
                label: {
                  formatter: `现金(元): \n\n ￥${records.totalReceive || 0}`,
                  show: true,
                  position: "center",
                },
                data: [],
              },
            ],
          };
          for (const [idx, arr] of totalArr.entries()) {
            option.series[0].data.length = 0;
            option.series[0].label.formatter = `现金(元)：\n\n ￥${totalAmount[idx]}`;
            for (const val of arr) {
              option.series[0].data.push({
                name: val.name,
                value: val.amount || 0,
              });
            }
            this.$echarts
              .init(document.getElementById(`chart${idx + 1}`))
              .setOption(option);
          }
        }
      });
    },
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      if (oldVal && newVal.id !== oldVal.id) {
        this.getSumFormData();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.cont {
  .title {
    margin: 35px 0 10px 0;
    h3 {
      font-size: 18px;
      span {
        color: #b9b9b9;
        font-weight: normal;
        font-size: 12px;
        margin-left: 6px;
      }
    }
  }
  .card-box {
    background: #ffffff;
    dl {
      display: flex;
      align-items: center;
      padding: 30px 0 30px 30px;
      width: 33.33%;
      position: relative;
      dt {
        margin: 0 20px 0 0;
        .image {
          width: 48px;
          height: 48px;
          background-size: 100% 100%;
        }
      }
      dt .image1 {
        background-image: url("../../../assets/make-note-icon1.png");
      }
      dt .image2 {
        background-image: url("../../../assets/make-note-icon2.png");
      }
      dt .image3 {
        background-image: url("../../../assets/make-note-icon3.png");
      }
      dd {
        p {
          font-size: 14px;
          color: #999999;
          margin-bottom: 10px;
        }
        span {
          font-size: 28px;
          color: #087ffd;
        }
      }
    }
    dl:before {
      position: absolute;
      content: "";
      width: 1px;
      height: 60px;
      background-color: #ccced9;
      right: 0;
      top: 30px;
    }
  }
  .chart-box {
    padding: 0 0 30px 0;
    .chart-wrap {
      margin: 20px 0 0 0;
      & > div:nth-child(1) {
        padding: 0 15px 0 0;
      }
      & > div:nth-child(2) {
        padding: 0 0 0 15px;
      }
      .chart {
        background: #ffffff;
        width: 100%;
        & > div {
          width: 800px;
          height: 300px;
        }
      }
    }
    .table-box {
      background: #ffffff;
      padding: 0 0 10px 0;
    }
  }
  .detail-total {
    font-size: 14px;
    color: #666666;
    i {
      color: red;
      font-style: normal;
    }
  }
  .el-table {
    .el-image {
      width: 60px;
      height: 60px;
      margin: 0 3px;
      border: #cccccc solid 1px;
    }
  }
}
.tab-header {
  background: #d6e8fb;
  .tab-body-item {
    margin: 0 -1px -1px 0;
  }
}
.tab-body {
  // border:1px solid #CCC;
  background: #fff;
  .tab-body-item {
    border: 1px solid #ccc;
    margin: 0 -1px -1px 0;
    overflow: hidden;
  }
}
.tab-body,
.tab-header {
  .tab-body-item {
    padding: 10px;
  }
}
</style>
